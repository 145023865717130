export enum SidebarEnum {
  todo = 'todo',
  todo_new = 'todo_new',
  organizer = 'organizer',
  plans = 'plans',
  wallet = 'wallet',
  hubs = 'hubs',
  archive = 'archive',
  calendar = 'calendar',
  planner = 'planner',
  profile = 'profile',
  roadmap = 'roadmap',
  notification = 'notification',
  backlog = 'backlog',
  events = 'events',
  garage = 'garage',
  garage_new = 'garage_new',
  network = 'network',
  settings = 'settings',
  feedback = 'feedback',
  chat = 'chat',
}
