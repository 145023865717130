export enum PlannerItemStatusesEnum {
  todo = 'todo',
  'in_progress' = 'in_progress',
  done = 'done',
  backlog = 'backlog',
  late = 'late',
  missed = 'missed',
  went = 'went',
  going = 'going',
  'not_going' = 'not_going',
  pending = 'pending',
  maybe = 'maybe',
  paid = 'paid',
  unpaid = 'unpaid',
  'no-answer' = 'no-answer',
  scheduled = 'scheduled',
  decline = 'decline',
  accept = 'accept',
}
