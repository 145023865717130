import { styled, Button } from '@mui/material';

export const MuiCustomButton = styled(Button)(({ theme }) => ({
  '&.text-mui-button': {
    '&.MuiButton-root': {
      padding: '8px',
      borderRadius: '20px',
      backgroundColor: 'transparent',
      color: theme.palette.case.neutral.n600,
      svg: {
        path: {
          fill: theme.palette.case.neutral.n600,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.case.neutral.n300,
        color: theme.palette.case.neutral.n600,
        svg: {
          path: {
            fill: theme.palette.case.neutral.n600,
          },
        },
      },
      '&: disabled': {
        color: theme.palette.case.neutral.n400,
        backgroundColor: theme.palette.case.neutral.n100,
        svg: {
          path: {
            fill: theme.palette.case.neutral.n400,
          },
        },
      },
    },
  },

  '&.text-primary-mui-button': {
    '&.MuiButton-root': {
      padding: '8px',
      borderRadius: '20px',
      backgroundColor: 'transparent',
      color: theme.palette.case.primary.p600,
      svg: {
        path: {
          fill: theme.palette.case.primary.p600,
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.case.primary.p800,
        svg: {
          path: {
            fill: theme.palette.case.primary.p800,
          },
        },
      },
      '&: disabled': {
        color: theme.palette.case.neutral.n500,
        backgroundColor: 'transparent',
        svg: {
          path: {
            fill: theme.palette.case.neutral.n500,
          },
        },
      },
    },
  },

  '&.textLight-mui-button': {
    '&.MuiButton-textPrimary': {
      backgroundColor: theme.palette.case.primary.p50,
      color: theme.palette.case.primary.p700,
      svg: {
        path: {
          fill: theme.palette.case.primary.p700,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.case.primary.p100,
        color: theme.palette.case.primary.p700,
        svg: {
          path: {
            fill: theme.palette.case.primary.p700,
          },
        },
      },
      '&: disabled': {
        color: theme.palette.case.neutral.n400,
        backgroundColor: theme.palette.case.neutral.n100,
      },
    },
  },

  '&.MuiButton-containedPrimary': {
    svg: {
      path: {
        fill: theme.palette.case.neutral.n0,
      },
    },
  },
  '&.MuiButton-outlinedPrimary': {
    svg: {
      path: {
        fill: theme.palette.case.primary.p600,
      },
    },
    '&:hover': {
      svg: {
        path: {
          fill: theme.palette.case.primary.p800,
        },
      },
    },
  },

  '&.MuiButton-sizeSmall': {
    '.MuiButton-startIcon': {
      width: '16px',
      height: '16px',
    },
  },
}));
