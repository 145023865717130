import React, { FC, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/redux';
import BaseHeader from '../BaseHeader';

import { setShowNetworkFilter } from '../../../../../store/network/networkSlice';

type ArchiveHeaderProps = {
  isShowRightSidebar?: boolean;
};
const NetworkHeader: FC<ArchiveHeaderProps> = ({ isShowRightSidebar }) => {
  const dispatch = useAppDispatch();
  const { isShowFilter } = useAppSelector((state) => state.network.network);

  const handleHideNavigationPanel = useCallback(
    (value: boolean) => {
      dispatch(setShowNetworkFilter(value));
    },
    [dispatch],
  );

  return (
    <BaseHeader
      isShowHideNavigationBtn
      isShowNavigationPanel={isShowFilter}
      handleHideNavigationPanel={handleHideNavigationPanel}
      isShowRightSidebar={isShowRightSidebar}
    />
  );
};

export default NetworkHeader;
