export enum ModalNamesEnum {
  mediaViewer = 'media-viewer-modal',
  photoCrop = 'photo-crop-modal',
  featureInfo = 'feature-info-modal',
  baseConfirmModal = 'base-confirm-modal',
  checklistTemplatesModal = 'checklist-templates-modal',
  todoFiltersModal = 'todo-filters-modal',
  withCreateVariantsModal = 'with-create-variants-modal',
  purchaseModal = 'purchase-modal',
  confirmWithTwoVariantModal = 'confirm-with-two-variant',
  confirmModalWithThreeVariant = 'confirm-three-two-variant',
  confirmModalWithPassword = 'confirm-modal-with-password',
  todoConfirmModal = 'todo-confirm-modal',
  confirmModalWithSelect = 'confirm-modal-with-select',
  confirmDeclineModal = 'confirm-decline-modal',
  userFullNameModal = 'user-full-name-modal',
  termsModal = 'terms-modal',
  policyModal = 'policy-modal',
  generalSettings = 'general-settings-modal',
  createGarageItem = 'create-garage-item',
  profileGeneralInformation = 'profile-general-information',
  profileAppearance = 'profile-appearance',
  bodyArts = 'body-arts',
  profileContacts = 'profile-contacts',
  roadmapFiltersModal = 'roadmap-filters-modal',
  networkFiltersModal = 'network-filters-modal',
  contactsFiltersModal = 'contacts-filters-modal',
  backlogFiltersModal = 'backlog-filters-modal',
  eventsFiltersModal = 'events-filters-modal',
  archiveFiltersModal = 'archive-filters-modal',
  archiveSettingsModal = 'archive-settings-modal',
  calendarFiltersModal = 'calendar-filters-modal',
  plannerFiltersModal = 'planner-filters-modal',
  createTaskModal = 'create-task-modal',
  createTodoModal = 'create-todo-modal',
  viewTaskModal = 'view-task-modal',
  viewPaymentModal = 'view-payment-modal',
  viewTodoModal = 'view-todo-modal',
  createEventModal = 'create-event-modal',
  viewEventModal = 'view-event-modal',
  replyModal = 'reply-modal',
  groupChatModal = 'group-chat-modal',
  inviteNetworkModal = 'inviteNetworkModal',
  vehicleStickerModal = 'vehicleStickerModal',
  userProfileModal = 'userProfileModal',
  userShortProfileModal = 'userShortProfileModal',
  networkSharingModal = 'networkSharingModal',
  networkEditConnection = 'networkEditConnection',
  garageGeneralInfo = 'garage-general-info',
  createContactModal = 'create-contact-modal',
  garageLicense = 'garage-license',
  contactViewModal = 'contactViewModal',
  insuranceModal = 'insurance-modal',
  transportSharing = 'transport-sharing',
  payment = 'payment',
  downgradePlan = 'downgrade-plan',
  downgradePlanNew = 'downgrade-plan-new',
  shareModal = 'share-modal',
  attachFiles = 'attach-files',
  upgradePlan = 'upgrade-plan',
  addPlanCard = 'add-plan-card',
  promocodeEvent = 'promocodeEvent',
}
