export default {
  network: {
    status: {
      connected: 'Connected',
      pendingRequest: 'Pending request',
      pendingRequests: 'Pending requests',
      notConnected: 'Not connected',
      futureSentRequests: 'Future sent requests',
      futureSentRequest: 'Future sent request',
      futureSent: 'Future sent',
      sentRequest: 'Sent request',
      sentRequests: 'Sent requests',
      connections: 'Connections',
      contacts: 'Contacts',
      myNetwork: 'My network',
    },
    title: {
      connected: 'Connected',
      pendingRequest: 'Pending request',
      pendingRequests: 'Pending requests',
      futureSentRequests: 'Future sent requests',
      futureSent: 'Future sent',
      sentRequest: 'Sent request',
      sentRequests: 'Sent requests',
      connections: 'Connections',
      contacts: 'Contacts',
      incomingContact: 'Shared contacts',
    },
    invite: {
      title: 'Invite',
      subtitle: 'Let’s see if the person you’re searching for is already on hubmee',
    },
    inviteContact: {
      title: 'Send the Invitation',
      text: 'Please, select email or phone number to invite a new user',
    },
    createContact: {
      title: 'The contact is saved',
      text: 'Do you want to invite this person to register in the hubmee system?',
    },
    confirmMessages: {
      decline: 'Are you sure you want to decline this request?',
      cancel: 'Are you sure you want to cancel this request?',
      removeConnections: 'Are you sure you want to remove {{name}} from your network?',
      resend: 'Are you sure you want to resend this request?',
      invite: 'Are you sure you want to invite this user?',
    },
    tooltips: {
      alreadyInvited: 'Already invited',
      alreadySentRequest: `You've already sent a request`,
    },
    toasts: {
      sentInvite: 'The invitation has been successfully sent!',
      sentInviteContact: 'Invitation has been sent to the contact from your Network!',
      roleRequired: 'The Role In Tree field is required',
      contactCreated: 'New contact has been saved to your Network!',
      requestCanceled: 'The request has been successfully canceled',
      requestConfirm: 'The user has been successfully added to your network',
      requestResend: 'The request has been successfully resent',
    },
  },
};
