export default {
  general: {
    notAssigned: 'Not assigned',
    hubmeek: 'Hubmeek',
    me: 'Me',
    and: 'And',
    on: 'On',
    hello: 'Hello',
    friend: 'Friend',
    task: 'task',
    event: 'event',
    seconds: 'sec',
    step: 'step {{step}}',
    add: 'Add {{item}}',
    addNew: 'Add new {{item}}',
    completeRate: 'complete rate',
    item: 'item',
    sharedWith: 'Shared with',
    phone: 'phone',
    email: 'email',
    emailAddress: 'email address',
    transport: 'transport',
    fuel: 'fuel',
    address: 'address',
    bodyArt: 'body art',
    checklist: 'checklist',
    vehicle: 'vehicle',
    backlog: 'backlog',
    roadmap: 'roadmap',
    planName: 'plan name',
    nameEmailPhoneNumber: 'name, email or phone number',
    waistCircumference: 'waist circumference (in)',
    hipsCircumference: 'hips circumference (in)',
    bustCircumference: 'bust circumference (in)',
    relationshipStatus: 'relationship status',
    taskTitle: 'task title',
    checklistTitle: 'checklist title',
    noteTitle: 'note title',
    checklists: 'Checklists',
    notes: 'Notes',

    breadcrumbs: {
      checklists: 'Checklists',
      notes: 'Notes',
      profile: 'Profile',
      todo: 'To-Do',
      planner: {
        journal: 'Journal',
        calendar: 'Calendar',
      },
      organizer: 'Organizer',
      roadmap: 'Tasks',
      backlog: 'Backlog',
      events: 'Events',
      garage: 'Garage',
      network: {
        network: 'Network',
        contacts: 'Contacts',
      },
      chat: 'Chat',
      settings: {
        generalSettings: 'General Settings',
        plans: 'Plans',
        hubs: 'Hubs',
        wallet: 'Wallet',
        archive: 'Archive',
      },
      feedback: 'Feedback',
    },
    header: {
      selected: 'Selected',
      newTask: 'New Task',
      newChecklist: 'New Checklist',
      newEvent: 'New Event',
      newNote: 'New Note',
      editMode: 'Edit mode',
      appearance: 'Appearance',
      generalInformation: 'General Information',
      contacts: 'Contacts',
      newBodyArt: 'New Body Art',
      billingIssue: 'Billing Issue',
      paymentOptions: 'Payment options',
      shareWith: 'Share with',
      attachFiles: 'Attach Files',
    },
    plannerItemsRole: {
      owner: 'Owner',
      editor: 'Editor',
      viewer: 'Viewer',
    },
    byPersonFilters: {
      all: 'All',
      mine: 'Mine',
      createdByMe: 'Created by me',
    },
    shareFilters: {
      only_mine: 'My Items',
      shared_by_me: 'Shared by me',
      shared_with_me: 'Shared with me',
      all: 'All Items',
      completed: 'Hide completed',
    },

    containers: {
      contacts: 'Contacts',
      period: 'Period',
      payment: 'Payment',
      description: 'Description',
      gallery: 'Gallery',
      documents: 'Documents',
      assignees: 'Assignees',
      attendees: 'Attendees',
      physicalAddress: 'Physical Address',
      credentials: 'Credentials',
      location: 'Location',
      main: 'Main',
      call: 'Call',
      notes: 'Notes',
      company: 'Company',
      generalInformation: 'General information',
      createAContact: 'Create a contact',
      insurance: 'Insurance',
      licencePlateSticker: 'License Plate Registration',
      lPRegistration: 'LP registration',
      vehicleSticker: 'Vehicle Registration',
      licensePlate: 'License Plate',
      insuranceCad: 'Insurance Card',
      date: 'Date',
      deductibles: 'Deductibles',
      coveredPeople: 'Covered People',
      agency: 'Agency',
      agent: 'Agent',
      fee: 'Fee',
      renewOnline: 'Renew online',
      resident: 'Resident',
      zone: 'Zone',
      sharing: 'Sharing',
      note: 'Note',
      phoneNumber: 'Phone Number',
      selectAMemberToShareWith: 'Select a member to share with',
      cardInfo: 'Card Info',
      billingHistory: 'Billing History',
      rateUs: 'Rate Us',
      supportUs: 'Contact support',
      bodyParameters: 'Body Parameters',
      hair: 'Hair',
      eyes: 'Eyes',
      login: 'Login',
      homeAddress: 'Home Address',
      currentAddress: 'Current Address',
      currentLocation: 'Current location',
      personal: 'Personal',
      work: 'Work',
      appearance: 'Appearance',
      cardInformation: 'Card Information',
      plan: 'plan',
      allRequests: 'All requests',
      allNews: 'All news',
      authorizedLogins: 'Authorized  logins',
      typeInformation: '{{type}} information',
      moreDetails: 'More details',
      mainInformation: 'Main information',
      peopleCovered: 'People Covered',
      payments: 'Payments',
    },
    containerSubtitle: {
      gallery: 'Upload or drag & drop file, max {{count}} files in gallery',
      documents: 'max {{count}} files to upload',
    },
    containerInfo: {
      pictures: 'pictures',
      gallery: 'Upload or drag & drop file, max 20 files in gallery',
      documents: 'documents',
      photos: 'Photos',
      assignees: 'assignees',
      attendees: 'Attendees',
      shared: 'Shared',
      appearance: 'Body type, Height, Hair, Eyes, Wais, Shoe Size ',
      bodyArt: 'Body type, Height, Hair, Eyes, Wais, Shoe Size ',
      contacts: 'Home and Current address, phone, email',
      generalInformation: 'Full name, Gender, Birthday, Relationship, Documents',
    },
    assignPeopleColumnNames: {
      name: 'Name',
      permission: 'Permission',
    },
    socialMedia: {
      instagram: 'Instagram',
      facebook: 'Facebook',
      twitter: 'Twitter',
      googleTalk: 'GoogleTalk',
      icq: 'Icq',
      linkedIn: 'LinkedIn',
    },
    buttons: {
      upload: 'Upload',
      add: 'Add',
      apply: 'Apply',
      upgrade: 'Upgrade',
      decline: 'Decline',
      leave: 'Leave',
      stay: 'Stay',
      close: 'Close',
      done: 'Done',
      publish: 'Publish',
      readMore: 'Read more',
      accept: 'Accept',
      comingSoon: 'Coming soon',
      all: 'All',
      share: 'Share',
      addAgent: 'Add agent',
      today: 'Today',
      filters: 'Filters',
      late: 'late',
      tasks: 'Tasks',
      events: 'Events',
      submit: 'Submit',
      calendar: 'Calendar',
      confirm: 'Confirm',
      continue: 'Continue',
      back: 'Back',
      goodJobHubmee: 'Good job hubmee!',
      cancel: 'Cancel',
      loading: 'Loading...',
      activate: 'Activate',
      activated: 'Activated',
      deactivate: 'Deactivate',
      deleteTask: 'Delete Task',
      archive: 'Archive',
      addHubs: 'Add Hubs',
      reedMore: 'Read more',
      delete: 'Delete',
      edit: 'Edit',
      pin: 'Pin',
      unpin: 'Unpin',
      reply: 'Reply',
      download: 'Download',
      contacts: 'Contacts',
      network: 'Network',
      otherMembers: 'Other members',
      reset: 'Reset',
      journal: 'Journal',
      update: 'Update',
      week: 'Week',
      invite: 'Invite',
      chat: 'Chat',
      resend: 'Resend',
      save: 'Save',
      ok: 'Ok',
      turnOn: 'Turn on',
      turnOff: 'Turn off',
      resendVerificationCode: 'Resend verification code',
      resendVerificationEmail: 'Resend verification email',
      deleteChat: 'Delete chat',
      clearChat: 'Clear chat',
      leaveGroup: 'Leave chat',
      editChatInfo: 'Edit chat info',
      viewChatInfo: 'View chat info',
      send: 'Send',
      payNow: 'Pay now',
      contactSupport: 'Contact support',
      tryNow: 'Try now',
      pay: 'Pay',
      cancelSubscription: 'Cancel subscription',
      skip: 'Skip',
      resendCode: 'Resend code',
      submitCode: 'Submit code',
      sentMessage: 'Sent message',
      current: 'Current',
      choose: 'Choose',
      yes: 'Yes',
      no: 'No',
      next: 'Next',
      skipLPRegistration: 'Skip LP Registration',
      skipInsurance: 'Skip Insurance',
      skipVehicleRegistration: 'Skip Vehicle Registration',
      proceedToVehicleRegistration: 'Proceed to Vehicle Registration',
      proceedToInsurance: 'Proceed to Insurance',
      proceedToLPRegistration: 'Proceed to LP Registration',
      proceedToGallery: 'Proceed to Gallery',
      select: 'Select',
      useMyTrial: 'Use my trial',
      usePromoCode: 'Use promo code',
      start: 'Start',
    },
    modalNavigation: {
      main: 'Main',
      checklist: 'Checklist',
      notes: 'Notes',
      comments: 'Comments',
      activity: 'Activity',
      filters: 'Filters',
    },
    fieldNames: {
      pinCode: 'PIN code',
      code: 'Code',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      subject: 'Subject',
      bodyArtType: 'Body art type',
      artist: 'Artist',
      iAgreeToThe: 'I agree to the',
      salon: 'Salon',
      price: 'Price',
      emailOrPhoneNumber: 'Email or phone number',
      dateOfBirth: 'Date of Birth',
      confirm: 'Confirm',
      recurrence: 'Recurrence',
      phoneNumber: 'Phone number',
      address: 'Address',
      unverifiedEmail: 'Unverified Email',
      unverifiedPhone: 'Unverified Phone',
      password: 'Password',
      currentPassword: 'Current password',
      passwordConfirmation: 'Confirmation password',
      socialMedia: 'Social media',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      confirmEmail: 'Confirm Email',
      enterVerificationCodeHere: 'Enter verification code here',
      searchByText: 'Search by text',
      description: 'Description',
      licensePlate: 'License plate',
      starts: 'Starts',
      startsDate: 'Starts date',
      startsTime: 'Starts time',
      endsTime: 'Ends time',
      endsDate: 'Ends date',
      ends: 'Ends',
      eyeColor: 'Eye color',
      eyeWear: 'Eyewear',
      stars: 'stars',
      byType: 'By types',
      byTasksStatus: 'By tasks status',
      byEventsStatus: 'By events status',
      byPaymentsStatus: 'By payments status',
      time: 'Time',
      dueDate: 'Due-date',
      dueTime: 'Due-time',
      repeat: 'Repeat',
      title: 'Title',
      allDay: 'All day',
      due: 'Due',
      assignPeopleToTheTask: 'Assign people to the task',
      assignPeopleToTheEvent: 'Assign people to the event',
      selectAMemberToShareWith: 'Select a member to share with',
      inviteToGroupChat: 'Invite a member to the group chat',
      byTask: 'By task',
      byEvent: 'By event',
      byDate: 'By date',
      byPerson: 'By person',
      middleName: 'Middle name',
      role: 'Role',
      type: 'Type',
      priority: 'Priority',
      status: 'Status',
      location: 'Location',
      url: 'URL',
      meetingId: 'Meeting ID',
      login: 'Login',
      onlyForYourself: 'Only for yourself',
      onlyThis: 'Only this',
      thisAndAllNext: 'This and all next',
      all: 'All',
      forAllUsers: 'For all users',
      transportType: 'Transport type',
      year: 'Year',
      make: 'Make',
      hairType: 'Hair type',
      hairLength: 'Hair length',
      hairColor: 'Hair color',
      model: 'Model',
      exteriorColor: 'Exterior Color',
      interiorColor: 'Interior Color',
      vinNumber: 'Vin number',
      state: 'State',
      number: 'Number',
      bodyStyle: 'Body/Style',
      trim: 'Trim',
      fuelType: 'Fuel Type',
      engineType: 'Engine Type',
      transmission: 'Transmission',
      hybridType: 'Hybrid Type',
      mileage: 'Mileage',
      drivetrain: 'Drivetrain',
      countryOfAssembly: 'Country of Assembly',
      searchByNameEmailOrPhone: 'Search by name, email or phone number',
      roleInTheTree: 'Role in the tree',
      searchByName: 'Search by name',
      sex: 'Sex',
      bodyType: 'Body type',
      weightLB: 'Weight (LB)',
      heightFT: 'Height (FT)',
      heightIN: 'Height (IN)',
      waistIN: 'Waist (IN)',
      bustCupSize: 'Bust (cup size)',
      bustIN: 'Bust (IN)',
      shoeSize: 'Shoe size',
      hipsIN: 'Hips (IN)',
      relationship: 'Relationship',
      style: 'Style',
      firstName: 'First Name',
      lastName: 'Last Name',
      company: 'Company',
      nickName: 'Nickname',
      isMakeAsAContactTitle: 'Make as a contact title ',
      birthday: 'Birthday',
      gender: 'Gender',
      issuedBy: 'Issued by',
      policyNumber: 'Policy Number',
      policy: 'Policy',
      naic: 'NAIC',
      effectiveDate: 'Effective Date',
      expirationDate: 'Expiration Date',
      paymentDueDay: 'Payment Due Day',
      paymentDay: 'Payment  Day',
      frequency: 'Frequency',
      amount: 'Amount',
      fullAmount: 'Full Amount',
      accountBalance: 'Account Balance',
      discount: 'Discount',
      paid: 'Paid',
      minimumDue: 'Minimum Due',
      сollision: 'Collision',
      comprehensive: 'Comprehensive',
      fullName: 'Full name',
      agencyName: 'Agency name',
      agentName: 'Agent name',
      website: 'Website',
      purchaseDate: 'Purchase date',
      purchaseDueDate: 'Purchase due date',
      registrationID: 'Registration ID',
      pINCode: 'PIN code',
      pin: 'Pin',
      renewalFee: 'Renewal Fee',
      lateFee: 'Late Fee',
      administrativeFee: 'Administrative Fee',
      total: 'Total',
      totalAmount: 'Total amount',
      name: 'Name',
      resident: 'Resident',
      county: 'County',
      reference: 'Reference',
      stickerFee: 'Sticker fee',
      zone: 'Zone',
      zoneNumber: 'Zone number',
      zoneFee: 'Zone fee',
      notifyMe: 'Notify Me',
      paidInFull: 'Paid in Full',
      emailAndPhone: 'Email/Phone',
      enterYourPhone: 'Enter your phone',
      invoiceID: 'Invoice ID',
      cardholderName: 'Сardholder name',
      cardNumber: 'Card number',
      MMYY: 'MM/YY',
      CVC: 'CVC',
      describeYourIssueOrSuggestion: 'Describe your issue or suggestion',
      writeReplyHere: 'Write reply here...',
      events: 'Events',
      tasks: 'Tasks',
      task: 'task',
      event: 'Event',
      today: 'Today',
      credentials: 'Credentials',
    },

    eventType: {
      online: 'Online',
      inPerson: 'In person',
      call: 'Call',
    },
    eventRole: {
      host: 'Host',
      guest: 'Guest',
      call: 'Call',
    },
    placeholders: {
      enter_title: 'Enter title',
      select_due_date: 'Select due date',
      all_day: 'All day',
      write_description: 'Write description',
      add_documents: 'Add documents for quick access',
      add_your_documents: 'Add your documents for quick access',
      add_task_photos: 'Add photos to personalize your task',
      add_event_photos: 'Add photos to personalize your event',
      add_profile_photos: 'Add more photos to personalize your profile',
      add_garage_photos: 'Add more photos to personalize your transport',
      add_bodyArd_photos: 'Add more photos to personalize your body art',
      select_type: 'Select type',
      select_eyewear: 'Select eyewear',
      select_length: 'Select length',
      select_event_status: 'Select event status',
      select_task_status: 'Select task status',
      select_payment_status: 'Select payment status',
      filters_tasks: 'Filters (Tasks)',
      select_task_priority: 'Select task priority',
      select_dates: 'Select dates',
      select_assignee_or_creator: 'Select assignee or creator',
      select_task_creator: 'Select task creator',
      select_time: 'Select time',
      set_recurrence: 'Set recurrence',
      write_task_description: 'Write task description',
      enter_url: 'Enter URL',
      enter_id: 'Enter ID',
      enter_company: 'Enter company',
      enter_website: 'Enter website',
      enter_login: 'Enter login',
      enter_password: 'Enter password',
      enter_location: 'Enter location',
      enter_reference: 'Enter reference',
      select_role: 'Select role',
      select_or_enter_agent_name: 'Select or enter agent name',
      write_event_description: 'Write event description',
      select_year: 'Select year',
      select_manufacturer: 'Select manufacturer',
      enter_manufacturer: 'Enter manufacturer',
      select_model: 'Select model',
      enter_model: 'Enter model',
      select_body_style: 'Select body style',
      select_trim: 'Select trim',
      select_color: 'Select color',
      enter_engine_type: 'Enter type',
      enter_mileage: 'Enter mileage',
      enter_drivetrain: 'Enter drivetrain',
      enter_country: 'Enter country',
      write_vehicle_description: 'Write vehicle description',
      select_frequency: 'Select frequency',
      enter_full_name: 'Enter name',
      select_name: 'Select name',
      select_zone: 'Select zone',
      enter_your_first_name: 'Enter your first name',
      enter_your_last_name: 'Enter your last name',
      enter_first_name: 'Enter first name',
      enter_last_name: 'Enter last name',
      enter_your_middle_name: 'Enter your middle name',
      select_gender: 'Select gender',
      select_relationship_status: 'Select status',
      enter_number: 'Enter number',
      enter_address: 'Enter address',
      enter_name: 'Enter name',
      enter_pin: 'Enter PIN',
      enter_state: 'Enter state',
      select_state: 'Select state',
      select_county: 'Select county',
      enter_price: 'Enter price',
      select_body_type: 'Select your body type',
      enter_weight: 'Enter weight (lb)',
      enter_height_ft: 'Enter height (ft)',
      enter_height_in: 'Enter height (in)',
      enter_waist_circumference: 'Enter waist circumference (in)',
      enter_hips_circumference: 'Enter hips circumference (in)',
      select_bust_cup_size: 'Select bust (cup size)',
      select_bust_circumference: 'Select bust circumference(in)',
      select_shoe_size: 'Select shoe size',
      search_by_name_email_or_phone: 'Search by name, email or phone number',
      select_date: 'Select date',
      select_social_media: 'Select social media',
      enter_nickname: 'Enter nickname',
      write_note: 'Write note for the contact',
      enter_chat_name: 'Enter chat name',
      search_friends_name: 'Enter your friend’s name here',
      search_chat_name: 'Enter chat name here',
      enter_email: 'Enter email',
      enter_cardholder_name: 'Enter full name',
      write_feedback: 'Leave your feedback here',
      enter_promo_code: 'Enter promo code',
      search_by_plan_name: 'Search by plan name',
      search_by_title: 'Search by title',
      search_by_task_title: 'Search by task title',
      describe_experience: 'Describe your experience here',
      selectFilters: 'Select filters',
      searchBy: 'Search by {{props}}',
      // TODO DEPRECATED DO NOT USE THIS
      enterTitle: 'Enter {{props}} title',
      enterCardholderName: 'Enter Cardholder Name',
      writeDescription: 'Write {{props}} description',
      photos: 'Add more photos to personalize your profile',
      addPhotos: 'Add photos to personalize your {{props}}',
      tasksFilters: {
        byTask: 'Select task creator',
        byPerson: 'Select assignee or creator',
      },
      addNewItem: 'Add the new item here',
      pinCode: 'Enter PIN code',
      selectStatus: 'Select {{props}} status',
      enterYour: 'Enter your {{props}}',
      fullName: 'Enter full name',
      selectYour: 'Select your {{props}}',
      yourDocuments: 'Add your documents for quick access',
      selectType: 'Select {{props}} type',
      transmission: 'Select the transmission type',
      engineType: 'Enter engine type',
      hybridType: 'Enter hybrid type',
      byPerson: 'Select assignee or creator',
      byTask: 'Select task creator',
      selectRole: 'Select Role',
      taskRecurrence: 'Set task recurrence',
      eventRecurrence: 'Set event recurrence',
      exteriorColor: 'Select exterior color',
      interiorColor: 'Select interior color',
      enterSelectName: 'Enter/Select {{props}} name',
      enterContact: 'Enter contact {{props}}',
      selectContact: 'Select contact {{props}}',
      socialMedia: 'Select social media',
      website: 'Enter website address',
      login: 'Enter login',
      purchaseDate: 'Select purchase date',
      registrationID: 'Enter registration ID',
      pin: 'Enter PIN',
      renewalFee: 'Enter renewal fee',
      administrativeFee: 'Enter administrative fee',
      lateFee: 'Enter late fee',
      state: 'Enter state',
      county: 'Select county',
      number: 'Enter number',
      reference: 'Enter reference',
      registrationNumber: 'Enter registration number',
      zone: 'Select zone',
      zoneNumber: 'Enter zone number',
      zoneFee: 'Enter zone fee',
      documents: 'Add documents for quick access',
      type: 'Select type',
      date: 'Select date',
      byDate: 'Select dates',
      time: 'Select time',
      priority: 'Select task priority',
      artist: 'Enter artist name',
      salon: 'Enter salon name',
      price: 'Enter price',
      phone: 'Enter phone number',
      email: 'Enter email address',
      location: 'Enter location',
      url: 'Enter URL',
      meetingId: 'Enter meeting ID',
      password: 'Enter password',
      dueDate: 'Select due-date',
      manufactureYear: 'Select year',
      make: 'Select manufacturer',
      model: 'Select model',
      bodyStyle: 'Select body style',
      style: 'Select  style',
      trim: 'Select trim',
      mileage: 'Enter mileage',
      drivetrain: 'Enter drivetrain',
      countryAssembly: 'Enter country of assembly',
      vinNumber: 'Enter VIN number',
      issuedBy: 'Enter insurance company',
      policyNumber: 'Enter policy number',
      naic: 'Enter NAIC number',
      residentName: 'Select resident name',
      effectiveDate: 'Select effective date',
      paymentDueDay: 'Select payment due day',
      expirationDate: 'Select expiration date',
      frequency: 'Select frequency',
      fullAmount: 'Enter full amount',
      minimumDue: 'Enter minimum due',
      accountBalance: 'Enter account balance',
      collision: 'Enter collision',
      comprehensive: 'Enter comprehensive',
      contactsNote: 'Write note for the contact',
      emailOrPhoneNumber: 'Enter email or phone number',
      passwordConfirm: 'Confirm password',
      chatTitle: 'Enter chat name',
      searchFriendName: 'Enter your friend’s name here',
      searchChatName: 'Enter chat name here',
      feedback: 'Leave your feedback here',
      describeExperience: 'Describe your experience here',
      writeSomething: 'Write something...',
      searchPlaces: 'Search places...',
      typeYourFirstName: 'Type your first name',
      typeYourLastName: 'Type your last name',
      writeTitle: 'Write Title...',
      writeNotesText: 'Write notes text...',
      searchByText: 'Search by text ...',
      describeYourIssueOrSuggestion: 'Describe your issue or suggestion ...',
      selectAMember: 'Select a member',
      writeYourChecklistTextHere: 'Write your checklist text here...',
      message: 'Message...',
      securityCode: 'Security Code',
      phoneNumberOrEmail: 'phone number/email',
    },
    priority: {
      high: 'High',
      middle: 'Middle',
      low: 'Low',
      none: 'None',
    },
    period: {
      every: 'every',
      until: 'until',
      indefinitely: 'indefinitely',
      fifteenDays: '15 days',
      thirtyDays: '30 days',
      days: 'day(s)',
      week: 'week(s)',
      month: 'month(s)',
      th: 'th',
      year: 'year',
      day: 'day',
    },
    colors: {
      red: 'Red',
      yellow: 'Yellow',
      green: 'Green',
      purple: 'Purple',
      none: 'None',
    },
    permission: {
      editor: 'Can edit',
      viewer: 'View only',
    },
    actionMenus: {
      edit: 'Edit',
      task: 'Task',
      share: 'Share',
      editTitle: 'Edit title',
      editNote: 'Edit note',
      complete: 'Complete',
      uncomplete: 'Uncomplete',
      duplicate: 'Duplicate',
      convertToTask: 'Convert to task',
      event: 'Event',
      archive: 'Archive',
      unArchive: 'Unarchive',
      deleteTask: 'Delete',
      delete: 'Delete',
      deleteEvent: 'Delete',
      deleteBodyArt: 'Delete',
      deleteItem: 'Delete Item',
      removeYourself: 'Remove me',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      bodyArt: 'Body Art',
      appearance: 'Appearance',
      shareWith: 'Share with',
      cancelSharing: 'Cancel sharing',
      viewEdit: 'View / Edit',
      view: 'View',
      viewDetails: 'View Details',
      deleteCard: 'Delete card',
      remove: 'Remove',
      markAsRead: 'Mark as read',
      markAllAsRead: 'Mark all as read',
      removeAllNews: 'Remove all news',
      decline: 'Decline',
      accept: 'Accept',
    },
    todoActionMenuPreset: {
      shopping: 'Shopping',
      work: 'Work',
      products: 'Products',
      cleaning: 'Cleaning',
      party: 'Party',
      custom: 'Custom',
    },
    statuses: {
      todo: 'To do',
      in_progress: 'In progress',
      done: 'Done',
      backlog: 'Backlog',
      late: 'Late',
      missed: 'Missed',
      went: 'Went',
      going: 'Going',
      not_going: 'Not going',
      pending: 'Pending',
      maybe: 'Maybe',
      paid: 'Paid',
      upcoming: 'Upcoming',
      declined: 'Declined',
      pay: 'To pay',
      'no-answer': 'No answer',
      scheduled: 'Scheduled',
      accept: 'Accept',
      decline: 'Decline',
    },
    statusCopy: {
      copy: 'copy',
      copied: 'copied',
      titleByStatus: {
        copy: 'Copy',
        copied: 'Copied',
      },
    },
    modals: {
      securityText: 'Your security is important to us',
      confirmDeactivateHub: {
        title: 'Confirm',
        text: 'Are you sure you want to deactivate this hub?',
      },
      userFullNameModal: {
        title: 'Welcome to hubmee!',
        text: 'Let’s get to know each other',
      },
      confirmCancelSharing: {
        title: 'Confirm',
        text: 'Are you sure you want to cancel sharing?',
      },
      confirmCancelSubscription: {
        title: 'Confirm',
        text: 'Are you sure you want to cancel the subscription?',
      },
      confirmDeclineTaskModal: {
        title: 'Decline task',
        description1: 'You can leave a comment that will be visible to other task assignees.',
      },
      confirmDeclineEventModal: {
        title: 'Decline event',
        description1: 'You can leave a comment that will be visible to other event attendees.',
        description2: 'For instance, you could suggest a different time for the event.',
      },
      confirmLogOut: {
        title: 'Confirm',
        text: 'Are you sure you want to log out?',
      },
      securityWipeData: {
        title: 'Confirm',
        text: "We are sorry to hear that you want to deactivate your account. If there's anything we can do to convince you to stay, let us know. If you are sure, type in the password and all your data will be lost forever",
      },
      confirmChangeStatusOnBacklog: {
        title: 'Confirm',
        text: 'All dates will be cleared by moving the task to the backlog. Are you sure you want to continue?',
      },
      confirmChangeStatusForAssignUser: {
        title: 'Confirm',
        text: 'Do you want to change status for this assignee?',
      },
      confirmChangeStatusForAllUsersOrYourself: {
        title: 'Do you want to change the status?',
      },
      confirmArchiveTask: {
        title: 'Confirm',
        text: 'Do you want to archive the Task?',
      },
      confirmArchiveEvent: {
        title: 'Confirm',
        text: 'Do you want to archive the Event?',
      },
      confirmNotGoingStatus: {
        title: 'Confirm',
        text: 'This event will be deleted from your Journal and Calendar after you choose status "Not Going"',
      },
      confirmRemoveYourselfFromTask: {
        title: 'Confirm',
        text: 'After this action, you will be able to see this task only in the Tasks page',
      },
      confirmUnArchiveTask: {
        title: 'Confirm',
        text: 'Are you sure you want to unarchive this task?',
      },
      confirmUnArchiveEvent: {
        title: 'Confirm',
        text: 'Are you sure you want to unarchive this event?',
      },
      unsavedData: {
        title: 'Unsaved changes',
        text: 'Unsaved data will be lost. You really want it?',
      },
      unsavedDataWithProgress: {
        title: 'Unsaved changes',
        text: 'Your progress of filling the information is {{progress}}%. If you leave the page your data will be lost.',
        subText: 'Are you sure you want to close this page?',
      },
      deleteCard: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this card?',
      },
      deleteTask: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this task?',
      },
      deleteTodo: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this checklist?',
      },
      deleteNote: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this note?',
      },
      convertChecklistToTask: {
        title: 'Confirm',
        text: 'The checklist will be converted to the task and removed from the Checklists. Are you sure you want to continue?',
      },
      convertChecklistItemToTask: {
        title: 'Confirm',
        text: 'The item will be converted to the task and removed from the Сhecklists, are you sure you want to continue?',
      },
      deleteMessage: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this message?',
      },
      deleteLoginDevice: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this device?',
      },
      deleteTransport: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this transport?',
      },
      deleteInsurance: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this insurance?',
      },
      deleteInfoBox: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this infobox?',
      },
      removeYourselfFromTask: {
        title: 'Confirm',
        text: 'Are you sure you want to remove yourself from this task?',
      },
      removeYourselfFromTodo: {
        title: 'Confirm',
        text: 'Are you sure you want to remove yourself from this checklist?',
      },
      removeYourselfFromNote: {
        title: 'Confirm',
        text: 'Are you sure you want to remove yourself from this note?',
      },
      removeYourselfFromEvent: {
        title: 'Confirm',
        text: 'Are you sure you want to remove yourself from this event?',
      },
      deleteEvent: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this event?',
      },
      deleteBodyArt: {
        title: 'Confirm',
        text: 'Are you sure you want to delete this body art?',
      },
      deleteRecurringItemModal: {
        text: 'Do you want to delete this {{type}}?',
      },
      confirmRecurringModal: {
        title: 'Confirm',
        text: 'Do you want to save changes?',
      },
    },
    notifications: {
      socialMediaAdded: 'The social media has been successfully added',
      socialMediaDeleted: 'The social media has been successfully deleted',
      defaultError: 'Something went wrong',
      purchaseError: 'You need to purchase a plan to continue using the hubmee app.',
      notHaveAccessError: 'You do not have access to the entity',
      profileAvatarUpdated: 'The profile avatar has been successfully updated ',
      profileAvatarAdded: 'The profile avatar has been successfully added ',
      profileAvatarDeleted: 'The profile avatar has been successfully deleted ',
      profileBackgroundUpdated: 'The profile background has been successfully updated ',
      profileBackgroundAdded: 'The profile background has been successfully added ',
      profileBackgroundDeleted: 'The profile background has been successfully deleted ',
      expiredPhoneCode: 'This code has expired! Please click on a resend button!',
      codeInvalid: 'This code is invalid',
      resendLink: 'The link has been has been successfully resend',
      activateHub: 'The hub has been successfully activated',
      deactivateHub: 'The hub has been successfully deactivated',
      changePassword: 'The password has been successfully changed',
      generalSettings: 'General settings have been successfully updated',
      profileGeneralInfoUpdated: 'General information has been successfully updated',
      feedbackSent: 'Your feedback has been successfully sent',
      bodyArtCreated: 'The body art has been successfully created',
      bodyArtUpdated: 'The body art has been successfully updated',
      bodyArtDeleted: 'The body art has been successfully deleted',
      profileAppearanceUpdated: 'Appearance has been successfully updated',
      profileContactsUpdated: 'Contacts have been successfully updated',
      verifyPhone: 'The phone number has been successfully verified',
      verifyEmail: 'The email has been successfully verified',
      resendVerificationCode: 'The verification code has been successfully send',
      resendVerificationEmail: 'The verification email has been successfully send',
      sessionTimeout: 'Session timeout',
      expiredCode: 'The code is expired',
      taskAdded: 'The task has been successfully added',
      todoAdded: 'The checklist has been successfully added',
      noteAdded: 'The note has been successfully added',
      taskUpdated: 'The task has been successfully updated',
      todoUpdated: 'The checklist has been successfully updated',
      noteUpdated: 'The note has been successfully updated',
      success: 'Success',
      taskStatusUpdated: 'The task status has been successfully updated',
      taskUserStatusUpdated: 'The user status has been successfully updated',
      taskDeleted: 'The task has been successfully deleted',
      todoDeleted: 'The checklist has been successfully deleted',
      noteDeleted: 'The note has been successfully deleted',
      todoDuplicated: 'The checklist has been successfully duplicated',
      checklistConverted: 'The checklist has been successfully converted to task',
      removeFromTask: 'You have been successfully removed from task',
      removeFromTodo: 'You have been successfully removed from the checklist',
      removeFromNote: 'You have been successfully removed from the note',
      archiveTask: 'The task has been successfully archived',
      unArchiveTask: 'The task has been successfully unarchived',
      eventAdded: 'The event has been successfully added',
      eventUpdated: 'The event has been successfully updated',
      paymentUpdated: 'The payment has been successfully updated',
      eventDeleted: 'The event has been successfully deleted',
      removeFromEvent: 'You have been successfully removed from event',
      archiveEvent: 'The event has been successfully archived',
      unArchiveEvent: 'The event has been successfully unarchived',
      taskNotFound: 'The Task is not found',
      eventNotFound: 'The Event is not found',
      transportDeleted: 'The transport has been successfully deleted',
      insuranceDeleted: 'The Insurance has been successfully deleted',
      licensePlateStickerDeleted: 'The License Plate Registration has been successfully deleted',
      vehicleStickerDeleted: 'The Vehicle Registration has been successfully deleted',
      eventStatusUpdated: 'The event status has been successfully updated',
      paymentStatusUpdated: 'The payment status has been successfully updated',
      contactWasSuccessfullyShared: 'The contact was successfully shared',
      assignedToTaskBy: 'You’ve been invited to the task: "{{title}}" by',
      invitedToEventBy: 'You’ve been invited to the event: "{{title}}" by',
      invitedToTodoBy: 'You’ve been invited to the checklist: "{{title}}" by',
      invitedToNoteBy: 'You’ve been invited to the note: "{{title}}" by',
      taskDeletedBy: 'The Task "{{title}}" deleted by',
      todoDeletedBy: 'The "{{title}}" checklist deleted by',
      eventDeletedBy: 'The Event "{{title}}" deleted by',
      taskArchivedBy: 'The Task "{{title}}" archived by',
      eventArchivedBy: 'The Event "{{title}}" archived by',
      taskUnArchivedBy: 'The Task "{{title}}" unarchived by',
      eventUnArchivedBy: 'The Event "{{title}}" unarchived by',
      taskUpdatedBy: 'The Task "{{title}}" was updated by',

      eventUpdatedBy: 'The Event "{{title}}" was updated by',
      todoUpdatedBy: 'The "{{title}}" checklist  was updated by',
      noteUpdatedBy: 'The "{{title}}" note was updated by',
      taskStatusChangedBy: 'The Status at the task "{{title}}" has been changed by',
      eventStatusChangedBy: 'The Status at the event "{{title}}" has been changed by',
      newUserAssignedToTask: 'New people have been assigned to the task "{{title}}" by',
      newPeopleInvitedToEvent: 'New people have been invited to the event "{{title}}" by',
      newPeopleInvitedToTodo: 'New people have been invited to the checklist "{{title}}" by',
      newPeopleInvitedToNote: 'New people have been invited to the note "{{title}}" by',

      peopleDeletedFromTask: ' An Assignee was deleted from the event "{{title}}" by',
      peopleDeletedFromEvent: ' An Attendee was deleted from the event "{{title}}" by',
      peopleDeletedFromTodo: ' An Assignee was deleted from the checklist "{{title}}" by',
      peopleDeletedFromNotes: ' An Assignee was deleted from the notes "{{title}}" by',
      peopleRemovedThemselfFromTask: 'An Assignee has removed themself from the task "{{title}}',
      peopleRemovedThemselfFromEvent: 'An Attendee has removed themself from the event "{{title}}',
      peopleRemovedThemselfFromTodo: 'An Assignee has removed themself from the checklist "{{title}}',
      thisHubIsNotActivated: 'This HUB is not activated',
      notInvitedToEvent: "Unfortunately, you haven't been invited to this event",
      notInvitedToChecklist: "Unfortunately, this checklist wasn't shared with you",
      notAssignedToTask: "Unfortunately, you haven't been assigned to this task",
      notAssignedToEvent: 'Unfortunately, you are not invited to this event',
      userSuccessfullyDeleted: 'The user has been successfully deleted',
      contactSuccessfullyDeleted: 'The contact has been successfully deleted',
      successfullyChanges: 'The changes have been successfully saved',
      newActivationLink:
        'We`re sorry, but the link you are trying to use has already expired! Please register again to get a new activation link.',
      checklistDidNotSharedWithYou: 'Unfortunately, this checklist did not shared with you',
      notesDidNotSharedWithYou: 'Unfortunately, this note did not shared with you',
      checklistNotFound: 'The Checklist is not found',
      noteNoteFound: 'The Note is not found',
      transportCreated: 'Transport has been successfully created',
    },
    tooltips: {
      copy: 'Copy',
      comingSoon: 'Coming Soon',
      remove: 'Remove',
      removeYourself: 'Remove me',
      showMoreInfo: 'Show more info',
      changeStatus: 'Change status',
      openInGoogleMaps: 'Open in Google Maps',
      editRole: 'Edit Role',
      theCurrentAddressTheSame: 'The current address is the same',
      thisHubIsNotActivated: 'This HUB is not activated',
      activateHubs: 'Upgrade your plan',
      includeInYourPlan: 'Included in your plan',
      showAll: 'Show all',
      share: 'Share',
      editThisDataInGarage: 'Edit this data in Garage',
      addPeopleInsurance:
        'Add people who are also covered by your vehicle insurance in the event of an incident.',
      selectTypeTransport:
        'First, select the type for your transport, and the rest of the fields will become clickable.',
      addInformationInsurance:
        'Add information about your insurance agency to conveniently and quickly view the necessary data.',
      activateNotifyMe:
        'Activate the Notify Me button, and hubmee will remind you of important dates for your insurance. Please be attentive when filling in information about insurance. Many fields are dependent on each other, so if some of them are not clickable, you may try to fill in the higher priority fields first.',
      pinnedByDefaultHub: 'This HUB is pinned by default',
      monthlyPlanDisabled: 'After your annual plan expires, you can buy a monthly plan',
    },
    errorMessage: {
      phoneNumberRule: 'A phone number must start with + and country code.',
    },

    leftSidebar: {
      hubsInfoText:
        'We have prepared an exciting update, and now it’s even easier to choose valuable features. So go to HUBS, add your favorite tabs and enjoy hubmee to the fullest!',
      items: {
        logOut: {
          label: 'Log out',
          subItems: [],
        },
        todo: {
          label: 'To-Do',
          subItems: [],
        },
        todo_new: {
          label: 'To-Do 2.0',
          subItems: [],
        },
        hubs: {
          label: 'Hubs',
          subItems: [],
        },
        organizer: {
          label: 'Organizer',
          subItems: [],
        },
        profile: {
          label: 'Profile',
          subItems: [],
        },
        plans: {
          label: 'Plans',
          subItems: [],
        },
        wallet: {
          label: 'Wallet',
          subItems: [],
        },
        archive: {
          label: 'Archive',
          subItems: [],
        },
        planner: {
          label: 'Journal',
          subItems: [],
        },
        calendar: {
          label: 'Calendar',
          subItems: [],
        },
        tasks: {
          label: 'Tasks',
          subItems: [],
        },
        backlog: {
          label: 'Backlog',
          subItems: [],
        },
        events: {
          label: 'Events',
          subItems: [],
        },
        garage: {
          label: 'Garage',
          subItems: [],
        },
        garage_new: {
          label: 'Garage 3.0',
          subItems: [],
        },
        network: {
          label: 'Network',
          subItems: [],
        },
        chat: {
          label: 'Chat',
          subItems: [],
        },
        settings: {
          label: 'Settings',
          subItems: [],
        },
        feedback: {
          label: 'Feedback',
          subItems: [],
        },
      },
    },
    planItems: {
      todo: 'To-Do',
      journal: 'Journal',
      roadmap: 'Tasks',
      backlog: 'Backlog',
      events: 'Events',
      garage: 'Garage',
      network: 'Network',
      chat: 'Chat',
      settings: 'Settings',
      feedback: 'Feedback',
      calendar: 'Calendar',
      archive: 'Archive',
    },

    gender: {
      male: 'Male',
      female: 'Female',
      unspecified: 'Unspecified',
      undisclosed: 'Undisclosed',
    },
    bodyType: {
      petite: 'Petite',
      slim: 'Slim',
      athletic: 'Athletic',
      average: 'Average',
      fewExtraPounds: 'Few extra pounds',
      fullFigured: 'Full figured',
      largeAndLovely: 'Large and lovely',
      other: 'Other',
    },
    PlannerItemModelType: {
      task: 'Tasks',
      event: 'Events',
      payment: 'Payments',
    },
    bodyArtType: {
      branding: 'Branding',
      earrings: 'Earrings',
      piercing: 'Piercing',
      tattoo: 'Tattoo',
      permanentMakeup: 'Permanent makeup',
      other: 'Other',
    },
    bustSize: {
      a: 'A',
      b: 'B',
      c: 'C',
      d: 'D',
      dd: 'DD',
      ddd: 'DDD',
      'f+': 'F+',
      e: 'E',
      g: 'G',
    },
    hairLength: {
      bald: 'Bald',
      baldOnTop: 'Bald on top',
      shaved: 'Shaved',
      short: 'Short',
      medium: 'Medium',
      long: 'Long',
      other: 'Other',
    },
    hairType: {
      straight: 'Straight',
      wavy: 'Wavy',
      curly: 'Curly',
      other: 'Other',
    },
    hairColor: {
      white: 'White',
      yellow: 'Yellow',
      darkBrown: 'Dark Brown',
      blond: 'Blond',
      black: 'Black',
      gray: 'Gray',
      brown: 'Brown',
      silver: 'Silver',
      changesFrequently: 'Changes Frequently',
      other: 'Other',
    },
    eyeColor: {
      blue: 'Blue',
      black: 'Black',
      gray: 'Gray',
      brown: 'Brown',
      hazel: 'Hazel',
      green: 'Green',
      changesFrequently: 'Changes Frequently',
      other: 'Other',
    },
    eyewear: {
      contactLenses: 'Contact Lenses',
      glasses: 'Glasses',
      contactLensesGlasses: 'Contact Lenses & Glasses',
      other: 'Other',
    },

    relationship: {
      single: 'Single',
      in_a_relationship: 'In a relationship',
      engaged: 'Engaged',
      married: 'Married',
      in_a_civil_union: 'In a civil union',
      in_an_open_relationship: 'In an open relationship',
      its_complicated: 'It’s complicated',
      separated: 'Separated',
      divorced: 'Divorced',
      widowed: 'Widowed',
    },
    recurringWeeklyDays: {
      sun: 'Sun',
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
    },
    fieldType: {
      home: 'Home',
      work: 'Work',
      main: 'Main',
      other: 'Other',
      personal: 'Personal',
      school: 'School',
      homePage: 'Home page',
    },
    recurringType: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      custom: 'Custom',
    },
  },
};
