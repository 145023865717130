import { Box, styled } from '@mui/material';
import { AvatarSizeEnum } from '../../../shared/enums/avatarSize.enum';

type SlicedUserContainerProps = {
  isShowUserListPopover?: boolean;
  size: 'extraSmall' | 'small' | 'medium';
};

export const AvatarStyleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isContainSlicedUser' && prop !== 'isShowSlicedUserBlock',
})<{
  isContainSlicedUser?: boolean;
  isShowSlicedUserBlock?: boolean;
}>(({ isContainSlicedUser, isShowSlicedUserBlock }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: isContainSlicedUser ? (isShowSlicedUserBlock ? '0px' : '6px') : '0px',
}));

export const SlicedUserContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isShowUserListPopover' && prop !== 'size',
})<SlicedUserContainerProps>(({ theme, isShowUserListPopover, size }) => ({
  position: 'relative',
  cursor: isShowUserListPopover ? 'pointer' : 'default',

  width: AvatarSizeEnum[size],
  height: AvatarSizeEnum[size],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.case.contrast.white,
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: '50%',

  fontSize: theme.typography.label.fontSize,
  lineHeight: theme.typography.label.lineHeight,
  fontWeight: theme.typography.label.fontWeight,
  flexShrink: 0,
  zIndex: 1,
}));
