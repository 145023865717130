export default {
  auth: {
    title: {
      welcomeToHubmee: 'Welcome to hubmee!',
      welcomeBack: 'Welcome Back!',
      continuePhone: 'Continue with phone',
      continueEmail: 'Continue with email',
      verifyEmail: 'Verify your {{props}}',
      signIn: 'Sign-in',
      resetPassword: 'Reset Password',
      enterNewPassword: 'Enter New Password',
      success: 'Success',
      checkEmail: 'Let’s cheсk email!',
      verificationAccount: 'Verification account',
      phoneVerification: 'Phone verification',
    },
    text: {
      sendVerificationCode: 'We’ll send you a verification code',
      sendEmail: 'We’ll send you an email to confirm your address',
      checkEmail:
        'This email is not verified. Check your email inbox, junk and trash please! You have 10 days to activate your account, otherwise your activation link will expire.',
      enterNewPassword: 'Enter New Password',
      haveNotAccount: 'Don’t have an account?',
      or: 'or',
      orSignIn: 'Or sign in',
      continueWithSocialNetwork: 'Continue with social network',
      SmartHelper: 'Personal manager for simplifying your life.',
      successDescription: 'Check your email inbox, junk and trash please!',
      periodToActivateAccount:
        'Check your email inbox, junk and trash please! You have 10 days to activate your account, otherwise your activation link will expire.',
      alreadyHaveAnAccount: 'Already have an account?',
      and: 'and',
      typeVerificationCode: 'Please enter the verification code sent to your {{props}}',
      IAgree: "By proceeding, I agree to hubmee's",
      didntCode: 'Didn’t get the code?',
      resendIn: 'Resend in: {{count}} sec',
    },
    links: {
      signIn: 'Sign In',
      signUp: 'Sign Up',
      continue: 'Continue',
      resend: 'Resend',
      wrongEmail: 'Wrong Email?',
      wrongPhone: 'Wrong Phone?',
      verify: 'Verify',
      continueEmail: 'Continue with Email',
      continuePhone: 'Continue with Phone',
      forgotPassword: 'Forgot password?',
      resendTheLink: 'Resend the link',
      wrongNumber: 'Wrong number?',
      terms: 'Terms of Use',
      policy: 'Private Policy',
    },
  },
};
