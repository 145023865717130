export default {
  onboarding: {
    fistStep: {
      title: 'First, let me know how you feel today:',
      quoteText: 'Welcome to hubmee family!',
      subtitle: 'Dear, {{userName}}',
      selectors: {
        happy: 'Happy',
        sad: 'Sad',
        excited: 'Excited',
        lazy: 'Lazy',
        tired: 'Tired',
        angry: 'Angry',
      },
    },
    secondStep: {
      title: 'You identify yourself as:',
      quoteText: {
        first: "It's so great that you feel good. Let's keep it this way. ",
        second: "We'll definitely work with that mood of yours. I'll keep it on my mind.",
        default: "Thank you for being honest. It's a strong character trait, you know.",
      },
      subtitle: 'Tell me a bit more,',
      selectors: {
        female: 'Female',
        male: 'Male',
        nonBinary: 'Non-binary',
        dontIdentify: "Don't identify with any gender",
      },
    },
    thirdStep: {
      title: 'When is your birthday?',
      quoteText:
        'The number is irrelevant unless, of course, you happen to be a bottle of wine, then the older, the better.',
      quoteAuthor: 'Joan Collins',
      subtitle: 'To create better experience for you, tell me',
    },
    fourthSlide: {
      title: 'What is your relationship status?',
      quoteText: 'How you love yourself is how you teach others to love you',
      quoteAuthor: 'Rupi Kaur.',
      selectors: {
        single: 'Single',
        InARelationship: 'In a relationship',
        married: 'Married',
      },
    },
    fifthSlide: {
      title: 'What areas of your life you want to improve with hubmee?',
      subtitle: 'You can choose as many areas as you need',
      quoteText: 'This is the most exciting part of the poll for 70% hubmee users',
      selectors: {
        kids: 'Kids',
        finances: 'Finances',
        pets: 'Pets',
        studies: 'Studies',
        health: 'Health',
        work: 'Work',
        home: 'Home',
        vehicle: 'Vehicle',
        habits: 'Habits',
      },
    },
    sixthSlide: {
      title: 'Choose the tools to manage your needs:',
      subtitle: 'The more options you choose - the better for your productivity',
      quoteText: "Finishing touches, and you'll get a personalized hubmee at your disposal.",
      selectors: {
        deadlines: 'Deadlines',
        meetings: 'Meetings',
        appointments: 'Appointments',
        events: 'Events',
        reminders: 'Reminders',
        tasks: 'Tasks',
        checklists: 'Checklists',
        payments: 'Payments',
        notes: 'Notes',
      },
    },
    seventhStep: {
      title: 'This is what I know about you so far...',
      quoteText: 'Thank you for your efforts, {{name}}!',
      ageLabel: "You're <0>{{age}}</0> years old,",
      relationshipLabel: '<0>{{relationship}}</0> and we will start managing your',
      and: 'and',
      byUsing: 'by using',
    },
    plansStep: {
      silver: '65% of users who started with Gold plan achieved their goals 3x faster.',
      gold: 'The Gold Plan - the best value for you!',
      platinum: 'The Platinum Plan - the best value for you!',
      quoteText: 'True value is determined by how much more we give in value than we take in payment',
      subtitle: 'According to your answers,',
      quoteAuthor: 'Bob Burg',
    },
    paymentStep: {
      title: 'Order Summary',
      promoError: 'This promo code is invalid. Try again.',
      access: 'With the {{plan}} plan you will get unlimited access to:',
      plan: '<0>{{plan}} Plan</0> / {{period}}',
      totalAmount: 'Total amount',
      sevenDays: '7 days',
      firstMonth: 'first month',
      paymentInfo: 'Payment information',
      promo: 'You just saved',
      trial: 'Trial sale',
    },
    trialStep: {
      title: 'I saw you hesitated,\n so here’s a 7-day {{package}} free trial for you.',
      subtitle:
        'By clicking <0>“Use my trial”</0> , you agree that in <0>7 days, {{amount}}$</0> will be automatically charged from your account if you don’t cancel.',
      specialOffer: 'Special offer',
    },
    promocodeStep: {
      title: 'Get <0>-50%</0> promo code — “newmee”',
      subtitle: "So, here's a present to better manage your needs, because we care:",
      discount: "This discount is valid only for the first {{period}}'s purchase",
      youCanUse: 'You can use',
      selectedPlan: 'only with {{plan}} plan.',
      forThePlan: 'for the {{plan}} plan',
    },
  },
};
