import { Box, styled, Badge } from '@mui/material';

type DefaultHeaderWrapperProps = {
  isOpenLeftSidebar: boolean;
  isShowRightSidebar?: boolean;
};

export const HeaderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.case.neutral.n75,
  zIndex: 10,
  height: '52px',
  borderBottom: `1px solid ${theme.palette.case.neutral.n200}`,
  position: 'fixed',
  left: 0,
  top: 0,
  [theme.breakpoints.down('md')]: {
    height: '48px',
    border: `none`,
    backgroundColor: theme.palette.case.contrast.white,
    boxShadow: '0px 4px 8px rgba(38, 44, 74, 0.08)',
  },
}));

export const DefaultHeaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpenLeftSidebar' && prop !== 'isShowRightSidebar',
})<DefaultHeaderWrapperProps>(({ theme, isOpenLeftSidebar, isShowRightSidebar }) => ({
  paddingLeft: isOpenLeftSidebar ? '226px' : '24px',
  paddingRight: isShowRightSidebar ? '82px' : '24px',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: '0.4s',
  }),
  [theme.breakpoints.down('md')]: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
  },
}));

export const HeaderRightBtnContainer = styled(Box)(() => ({
  marginLeft: 'auto',
  display: 'flex',
}));

export const HeaderFilterCounterBlock = styled(Box)(({ theme }) => ({
  width: '16px',
  lineHeight: '11px',
  paddingLeft: '1px',
  paddingTop: '1px',
  color: theme.palette.case.contrast.black,
  backgroundColor: theme.palette.case.neutral.n100,
  borderRadius: '50%',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const BadgeContainer = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    width: '8px',
    height: '8px',
    border: `1px solid ${theme.palette.case.contrast.white} `,
    borderRadius: '50%',
    right: '3px',
    top: '10px',
  },
}));
