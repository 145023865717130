import React, { FC, useState } from 'react';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import Linkify from 'react-linkify';
import { parsePhoneNumber } from 'react-phone-number-input';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { toUpper } from 'lodash';
import MuiIconButton from '../../buttons/MuiIconButton';
import MuiTooltip from '../../MuiTooltip';
import {
  MuiPhoneNumberInputViewContainer,
  MuiPhoneNumberInputViewContentContainer,
  MuiPhoneNumberInputViewLabel,
} from './MuiPhoneNumberInputView.style';

type MuiPhoneNumberInputViewProps = {
  label: string;
  content: string;
  country?: string;
  isShowBottomBorder?: boolean;
  isShowCopyBtn?: boolean;
};

export const codeToFlag = (code: string): string =>
  String.fromCodePoint(
    ...toUpper(code)
      .split('')
      .map((c) => 127397 + c.charCodeAt(0)),
  );

const MuiPhoneNumberInputView: FC<MuiPhoneNumberInputViewProps> = ({
  label,
  content,
  country,
  isShowBottomBorder = true,
  isShowCopyBtn = true,
}) => {
  const formatCountry = country ? country : parsePhoneNumber(content)?.country;
  const { t } = useTranslation();

  const STATUS_COPY = {
    COPY: t('general.statusCopy.copy'),
    COPIED: t('general.statusCopy.copied'),
  };
  const TITLE_BY_STATUS = {
    [STATUS_COPY.COPY]: t('general.statusCopy.titleByStatus.copy'),
    [STATUS_COPY.COPIED]: t('general.statusCopy.titleByStatus.copied'),
  };
  const [statusCopy, setStatusCopy] = useState(STATUS_COPY.COPY);
  const onClickCopy = () => {
    setStatusCopy(STATUS_COPY.COPIED);
  };
  const onClickAway = () => {
    setStatusCopy(STATUS_COPY.COPY);
  };

  return (
    <MuiPhoneNumberInputViewContainer isShowBottomBorder={isShowBottomBorder}>
      <MuiPhoneNumberInputViewLabel variant="label">{label}</MuiPhoneNumberInputViewLabel>
      <MuiPhoneNumberInputViewContentContainer
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {content !== '-' && content !== 'N/A' && (
            <Box sx={{ mr: '6px' }}>{codeToFlag(formatCountry || 'US')}</Box>
          )}

          <Typography
            sx={{
              overflow: 'hidden',
              pb: '2px',
            }}
            noWrap
            variant="default"
          >
            {content !== '-' && content !== 'N/A' ? (
              <a href={`tel:${content}`}>{content}</a>
            ) : (
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a onClick={(e) => e.stopPropagation()} target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {content}
              </Linkify>
            )}
          </Typography>
        </Box>
        <Box>
          {isShowCopyBtn && content !== '-' && content !== 'N/A' && (
            <ClickAwayListener onClickAway={() => onClickAway()}>
              <Box>
                <MuiTooltip title={TITLE_BY_STATUS[statusCopy]}>
                  <Box component="span">
                    <CopyToClipboard text={typeof content === 'string' ? content : ''}>
                      <MuiIconButton onClick={() => onClickCopy()} color="primary" size="small">
                        <ContentCopyIcon sx={{ '&: hover': { opacity: '0.7' } }} />
                      </MuiIconButton>
                    </CopyToClipboard>
                  </Box>
                </MuiTooltip>
              </Box>
            </ClickAwayListener>
          )}
        </Box>
      </MuiPhoneNumberInputViewContentContainer>
    </MuiPhoneNumberInputViewContainer>
  );
};

export default MuiPhoneNumberInputView;
