export default {
  garage: {
    transportType: {
      car: 'Car',
      motorcycle: 'Motorcycle',
      custom: 'Custom Transport',
    },
    styleType: {
      sportbike: 'Sportbike',
      cruisers: 'Cruisers',
      standart_naked: 'Standart / Naked',
      touring_sportTouring: 'Touring / Sport-Touring',
      dualSport: 'Dual-Sport / Adventure',
      offRoad: 'Off-Road',
      electric: 'Electric',
    },
    bodyType: {
      sedan: 'Sedan',
      sportCar: 'Sports Car',
      hatchback: 'Hatchback',
      muv_suv: 'MUV/SUV',
      coupe: 'Coupe',
      convertible: 'Convertible',
      wagon: 'Wagon (Station wagon)',
      jeep: 'Jeep',
      van: 'Van',
      minivan: 'Minivan',
      crossover: 'Crossover (CUV)',
      landaulet: 'Landaulet (Landaulette)',
      limousine: 'Limousine (Limo)',
      roadster: 'Roadster',
    },
    trimType: {
      CE: 'CE: Classic Edition or Custom Edition',
      DX: 'DX: Deluxe',
      DL: 'DL: Deluxe Level',
      EX: 'EX: Extra',
      GL: 'GL: Grade Level',
      GLE: 'GLE: Grade Level Extra',
      GT: 'GT: Grand Touring',
      LX: 'LX: Luxury',
      LE: 'LE: Luxury Edition',
      LS: 'LS: Luxury Sport or Luxury Special',
      LT: 'LT: Luxury Touring',
      LTD: 'LTD: Limited',
      LTZ: 'LTZ: Luxury Touring Special',
      SE: 'SE: Sport Edition or Special Edition or Special Equipment',
      SL: 'SL: Standard Level',
      SLE: 'SLE: Standard Level Extra',
      SLT: 'SLT: Standard Level Touring',
      SV: 'SV: Special Version',
      XLT: 'XLT: Extra Level Touring',
    },
    fuelType: {
      gasoline: 'Gasoline',
      electric: 'Electric',
      hybrid: 'Hybrid',
      diesel: 'Diesel',
      ethanol: 'Ethanol',
      hydrogen: 'Hydrogen',
      biodiesel: 'Biodiesel',
      naturalGas: 'Natural gas',
    },
    hybridType: {
      plugInHybrid: 'Plug-in hybrid',
      fullHybrid: 'Full hybrid(FHEV)',
      parallel: 'Parallel(Mild) hybrid',
      range: 'Range extender hybrid',
    },
    stickerType: {
      motorbike: 'Motorbike (MB)',
      passenger: 'Passenger (P)',
      largePassenger: 'Large Passenger (LP)',
      truck: 'Small Truck (ST)',
      largeTruck: 'Large Truck (LT)',
    },
    transmissionType: {
      single: 'Single-speed',
      automatic: 'Automatic',
      automaticManual: 'Automated-Manual',
      manual: 'Manual',
      continuously: 'Continuously variable (CVT)',
      tiptronic: 'Tiptronic Transmission',
      semiAutomatic: 'Semi-automatic',
      nonSynchronous: 'Non-synchronous',
      bicycle: 'Bicycle gearing',
    },
    motorcycleType: {
      sportbike: 'Sportbike',
      cruisers: 'Cruisers',
      standart: 'Standart / Naked',
      touring: 'Touring / Sport-Touring',
      dualSport: 'Dual-Sport / Adventure',
      offRoad: 'Off-Road',
      electric: 'Electric',
    },
    tooltips: {
      mainBlock:
        'Add the main information about the license Sticker and activate the Notify Me button, if you want hubmee to remind you of important dates for your Sticker.',
      renewOnlineBlock:
        'Fill in all the fields so that you can renew the license Sticker online at any time.',
    },
    colorType: {
      red: 'Red',
      yellow: 'Yellow',
      blue: 'Blue',
      green: 'Green',
      black: 'Black',
      gray: 'Gray',
      brown: 'Brown',
      white: 'White',
      purple: 'Purple',
      silver: 'Silver',
      other: 'other',
    },
    frequency: {
      none: 'None',
      once_week: 'Once a week',
      once_in_two_weeks: 'Once in two weeks',
      once_month: 'Once a month',
      once_in_six_months: 'Once in 6 months',
    },
  },
};
