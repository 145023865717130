import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { AvatarStyleContainer, SlicedUserContainer } from './MuiAvatarGroup.style';
import MuiTooltip from '../../MuiTooltip';
import UserListPopover from '../../popovers/UserListPopover';
import { ItemUserModel } from '../../../shared/models/itemUser.model';
import AvatarContainer from '../AvatarContainer';

type MuiAvatarGroupProps = {
  users: ItemUserModel[];
  owner: ItemUserModel;
  maxItemView?: number;
  isShowOwnerBlock?: boolean;
  isShowSlicedUsersBlock?: boolean;
  isContainOwnerInUsers?: boolean;
  isShowUserListPopover?: boolean;
  isShowAddUserBtn?: boolean;
  size?: 'extraSmall' | 'small' | 'medium';
  onClickShare?: () => void;
};
// ADD SIZE ENUMS
const MuiAvatarGroup: FC<MuiAvatarGroupProps> = ({
  users,
  owner,
  maxItemView = 2,
  isShowOwnerBlock = true,
  isShowSlicedUsersBlock = true,
  isShowUserListPopover = true,
  isContainOwnerInUsers = true,
  isShowAddUserBtn = true,
  size = 'small',
  onClickShare,
}) => {
  const { t } = useTranslation();
  const ownerCheckedUsersArr: ItemUserModel[] = useMemo(() => {
    return isContainOwnerInUsers
      ? users.findIndex((item) => item?.id === owner.id) >= 0
        ? users
        : [...users, owner]
      : users.filter((item: { id: number }) => item?.id !== owner?.id);
  }, [owner, users, isContainOwnerInUsers]);

  const slicedUsersArr = useMemo(() => {
    return ownerCheckedUsersArr.slice(0, maxItemView);
  }, [ownerCheckedUsersArr, maxItemView]);

  const slicedUsersCount = useMemo(() => {
    return ownerCheckedUsersArr.length - slicedUsersArr.length;
  }, [ownerCheckedUsersArr, slicedUsersArr.length]);

  const sortDataForUserPopover = useMemo(() => {
    let formattedArr = [];

    if (users.findIndex((item) => item?.id === owner?.id) >= 0) {
      formattedArr = users
        .map((item) => (item?.id === owner?.id ? { ...item, isOwner: true } : item))
        .sort((x, y) => {
          return x.isOwner === true ? -1 : y.isOwner === true ? 1 : 0;
        });
    } else {
      formattedArr = [...users, { ...owner, isOwner: true }].sort((x, y) => {
        return x.isOwner === true ? -1 : y.isOwner === true ? 1 : 0;
      });
    }

    return formattedArr;
  }, [owner, users]);

  return (
    <AvatarStyleContainer
      isContainSlicedUser={!!slicedUsersArr?.length}
      isShowSlicedUserBlock={isShowSlicedUsersBlock && slicedUsersCount > 0}
    >
      {isShowOwnerBlock && owner && (
        <Box sx={{ marginRight: '5px' }}>
          <UserListPopover
            onClickShare={onClickShare}
            isShowAddUserBtn={isShowAddUserBtn}
            users={sortDataForUserPopover}
          >
            <AvatarContainer
              isOwner
              firstName={owner.first_name}
              src={owner?.avatar?.additional_info?.size_urls?.avatar_icon || owner?.avatar?.url || ''}
              id={owner.id}
              size={size}
              lastName={owner.last_name}
            />
          </UserListPopover>
        </Box>
      )}
      {!!slicedUsersArr.length ? (
        <Box sx={{ display: 'flex', mr: slicedUsersCount > 0 ? '0px' : '13px' }}>
          {slicedUsersArr.map((item) => (
            <UserListPopover
              onClickShare={onClickShare}
              isShowAddUserBtn={isShowAddUserBtn}
              users={sortDataForUserPopover}
              key={item.id}
            >
              <AvatarContainer
                key={item.id}
                firstName={item.first_name}
                customStyle={{ marginRight: '-8px', '& .BaseBadge-badge': { right: '6px' } }}
                src={
                  item?.avatar
                    ? item?.avatar?.additional_info?.size_urls?.avatar_icon || item?.avatar?.url
                    : ''
                }
                id={item.id}
                size={size}
                lastName={item.last_name}
              />
            </UserListPopover>
          ))}
        </Box>
      ) : (
        <></>
      )}

      {isShowSlicedUsersBlock && (
        <>
          {slicedUsersCount > 0 ? (
            <Box>
              {isShowUserListPopover ? (
                <UserListPopover
                  onClickShare={onClickShare}
                  isShowAddUserBtn={isShowAddUserBtn}
                  users={sortDataForUserPopover}
                >
                  <MuiTooltip isHideOnMobile title={t('general.tooltips.showAll')}>
                    <SlicedUserContainer
                      sx={{ marginRight: slicedUsersCount > 0 ? '5px' : '0px' }}
                      size={size}
                      isShowUserListPopover
                    >
                      +{slicedUsersCount}
                    </SlicedUserContainer>
                  </MuiTooltip>
                </UserListPopover>
              ) : (
                <SlicedUserContainer size={size}>+{slicedUsersCount}</SlicedUserContainer>
              )}
            </Box>
          ) : (
            <></>
          )}
          <Box>
            {isShowAddUserBtn && onClickShare && (
              <MuiTooltip isHideOnMobile title={t('general.tooltips.share')}>
                <SlicedUserContainer
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickShare();
                  }}
                  isShowUserListPopover={isShowUserListPopover}
                  sx={{
                    fontSize: size === 'extraSmall' ? '10px' : '16px',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  }}
                  size={size}
                >
                  +
                </SlicedUserContainer>
              </MuiTooltip>
            )}
          </Box>
        </>
      )}
    </AvatarStyleContainer>
  );
};

export default memo(MuiAvatarGroup);
